/*!

 =========================================================
 * Light Bootstrap Dashboard React - v1.3.0
 * Based on Light Bootstrap Dashboard - v1.3.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/light-bootstrap-dashboard-react
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import 'lbd/variables';
@import 'lbd/mixins';

// Core CSS
@import 'lbd/typography';
@import 'lbd/misc';
@import 'lbd/sidebar-and-main-panel';
@import 'lbd/buttons';
@import 'lbd/inputs';

@import 'lbd/alerts';
@import 'lbd/tables';

@import 'lbd/checkbox-radio-switch';
@import 'lbd/navbars';
@import 'lbd/footers';

// Fancy Stuff
@import 'lbd/dropdown';
@import 'lbd/cards';
@import 'lbd/chartist';
@import 'lbd/responsive';

#meter-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  > div:first-child {
    width: 85%;
  }
  button {
    padding: 8px 12px;
    height: 40px;
  }
  .meterNumber-input {
    width: 100%;
  }
}

.col-sm-8 {
  width: 100% !important;
}

.ant-table-wrapper {
  overflow: auto !important;
}

@media (max-width: 768px) {
  .col-sm-8 {
    width: 100% !important;
  }
  .main-panel .content {
    padding: 30px 0px !important;
  }
}

.custom-message {
  margin: 5rem 4rem 0px 0px;
  div {
    max-width: 100%;
  }
  > div,
  input,
  textarea {
    width: 100%;
  }
  > h4 {
    font-weight: 700;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.inline {
  display: flex;
  margin-bottom: 2rem;
  > button {
    margin-left: 10px;
  }
}

.operation_icons {
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  padding: 3px 10px;
}

.ant-modal-title {
  font-weight: 800 !important;
  font-size: 2rem !important;
}
.ant-descriptions-item-label {
  font-weight: 800 !important;
}
