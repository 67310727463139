/*     General overwrite     */
body,
.wrapper {
  min-height: 100vh;
  position: relative;
  background-color: white;
}
a {
  color: $info-color;

  &:hover,
  &:focus {
    color: $info-states-color;
    text-decoration: none;
  }
}

a:focus,
a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  outline: 0;
}
.ui-slider-handle:focus,
.navbar-toggle,
input:focus {
  outline: 0 !important;
}

/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert {
  @include transition($general-transition-time, $transition-linear);
}

.sidebar .nav a,
.table > tbody > tr .td-actions .btn {
  @include transition($fast-transition-time, $transition-ease-in);
}

.btn {
  @include transition($ultra-fast-transition-time, $transition-ease-in);
}
.fa {
  width: 18px;
  text-align: center;
}
.margin-top {
  margin-top: 50px;
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.table-space {
  margin: 1rem;
}

.meter-banner {
  background: url('https://images.unsplash.com/photo-1458007683879-47560d7e33c3?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2227&q=80');
  min-height: 20vh;
  margin-bottom: 3rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.payment-banner {
  background: url('https://images.unsplash.com/photo-1580519542036-c47de6196ba5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2251&q=80');
  min-height: 20vh;
  margin-bottom: 3rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.loader-align {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-page {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
}

.left {
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('https://images.unsplash.com/photo-1519455953755-af066f52f1a6?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=80');
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.2s linear;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.right {
  right: 0;
  transition: all 0.3s linear;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  form {
    width: 50%;
  }
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.top-btns {
  display: flex;
  a{
    margin-left: auto;
    height: fit-content;
    margin-right: 1rem;
  }
}

.centered-flex {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .meter-banner {
    min-height: 10vh;
    margin-bottom: 2rem;
  }
  .login-page {
    display: grid;
    grid-template-columns: 1fr;
  }
  .right {
    height: 90vh;
    width: 100%;
    transition: all 0.2s linear;
  }
  .left {
    height: 10vh;
    width: 100%;
    transition: all 0.3s linear;
    text-align: center;
    background: url('https://images.unsplash.com/photo-1568145675395-66a2eda0c6d7?ixid=MXwxMjA3fDB8MHxzZWFyY2h8M3x8d2F0ZXJ8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60');
    h2 {
      font-size: 18px;
      font-weight: 800;
    }
  }
}
